import Link from 'next/link';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const HomeLayout: FunctionComponent<any> = ({ children }) => {
  return (
    <HomeContainer>
      <NavBar>
        <Link href="/">
          <h1
            style={{
              display: 'inline',
              marginBottom: '10px',
              fontSize: '20px',
            }}
          >
            exegesis
            <Logo height="50px" src="/logo_transparent.png" />
          </h1>
        </Link>
        <Link href="/login">
          <NavLink>login</NavLink>
        </Link>
        <Link href="/learn">
          <NavLink>learn</NavLink>
        </Link>
        <Link href="/merch">
          <NavLink>merch</NavLink>
        </Link>
        <a
          href="https://twitter.com/exegesis_app"
          rel="noreferrer"
          target="_blank"
          style={{ textDecoration: 'none' }}
        >
          <NavLink>twitter</NavLink>
        </a>
        {/* <Link href="/pricing">
          <NavLink>pricing</NavLink>
        </Link> */}
      </NavBar>
      <div>{children}</div>
    </HomeContainer>
  );
};

export default HomeLayout;

const NavLink = styled.a`
  margin-right: 20px;
  text-decoration: none;
  cursor: pointer;
  font-size: 20px;
`;

const NavBar = styled.div`
  /* position: sticky; */
  top: 20px;
`;

const HomeContainer = styled.div`
  width: 80vw;
  padding: 20px;
  /* max-width: 1000px; */
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20vh;

  @media only screen and (max-width: 600px) {
    width: 80vw;
  }
`;

const Logo = styled.img`
  vertical-align: middle;
  height: 35px;
  margin-left: 5px;
  transition: transform 1s;
  margin-right: 20px;

  :hover {
    transform: rotate(360deg);
  }
`;
