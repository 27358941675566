import { useState } from 'react';
import styled from 'styled-components';
const layer1 = '/exegesismech/exegesismech-9.png';
const layer2 = '/exegesismech/exegesismech-8.png';
const layer2back = '/exegesismech/exegesismech-7.png';
const layer3 = '/exegesismech/exegesismech-6.png';
const layer3back = '/exegesismech/exegesismech-5.png';
const layer4 = '/exegesismech/exegesismech-4.png';
const layer4back = '/exegesismech/exegesismech-3.png';
const layer5 = '/exegesismech/exegesismech-2.png';
const layer5back = '/exegesismech/exegesismech-1.png';

// animation where they reverse explode on entry
const imgs = [
  { image: layer5back, itop: 300, ileft: -300, htop: 100, hleft: -100 },
  { image: layer5, itop: 300, ileft: -300, htop: 100, hleft: -100 },

  { image: layer4back, itop: 300, ileft: -300, htop: 100, hleft: -100 },
  { image: layer4, itop: 300, ileft: -300, htop: 100, hleft: -100 },

  { image: layer3back, itop: -100, ileft: 100, htop: -50, hleft: 50 },
  { image: layer3, itop: -100, ileft: 100, htop: -50, hleft: 50 },

  { image: layer2back, itop: -300, ileft: 300, htop: -100, hleft: 100 },
  { image: layer2, itop: -300, ileft: 300, htop: -100, hleft: 100 },
  { image: layer1, itop: -300, ileft: 300, htop: -100, hleft: 100 },
];

// TODO animation where they slide in from off screen
// const imgs = [
// 	{ image: layer1, itop: -300, ileft: 300, htop: -100, hleft: 100 },

// 	{ image: layer2back, itop: -200, ileft: 200, htop: -50, hleft: 50 },
// 	{ image: layer2, itop: -200, ileft: 200, htop: -50, hleft: 50 },

// 	{ image: layer3back, itop: -100, ileft: 100, htop: -50, hleft: 50 },
// 	{ image: layer3, itop: -100, ileft: 100, htop: -50, hleft: 50 },

// 	{ image: layer4back, itop: 300, ileft: -300, htop: 100, hleft: -100 },
// 	{ image: layer4img, itop: 300, ileft: -300, htop: 100, hleft: -100 },
// 	{ image: layer4, itop: 300, ileft: -300, htop: 100, hleft: -100 },
// ]

export const ExegesisMech = () => {
  const [hover, set_hover] = useState(false);
  return (
    <SynesthesiaMechContainer
      onMouseOver={() => {
        set_hover(true);
      }}
      onMouseLeave={() => {
        set_hover(false);
      }}
    >
      {imgs.map((img, index) => {
        return (
          <SynesthesiaMechImage
            key={index}
            src={img.image}
            index={index}
            itop={img.itop}
            ileft={img.ileft}
            hover={hover}
            htop={img.htop}
            hleft={img.hleft}
          />
        );
      })}
    </SynesthesiaMechContainer>
  );
};

const SynesthesiaMechImage = styled.img<{
  index: number;
  itop: number;
  ileft: number;

  hover: boolean;
  htop: number;
  hleft: number;
}>`
  position: relative;
  top: ${(props) => `${props.index * -100}%`};
  height: 100%;
  width: 100%;
  /* @media only screen and (max-width: 600px) {
    width: 100%;
    height: auto;
  } */
  margin: 0px;
  padding: 0px;
  vertical-align: top;

  z-index: ${(props) => -100 + props.index};

  /* prevent selections and dragging */
  pointer-events: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  transition: transform 0.5s;
  @keyframes ${(props) => 'split' + props.index} {
    from {
      transform: translate(
        ${(props) => props.ileft}px,
        ${(props) => props.itop}px
      );
    }
    to {
      transform: translate(0px, 0px);
    }
  }
  animation-name: ${(props) => 'split' + props.index};
  /* animation-fill-mode: both; */
  animation-timing-function: ease-in-out;
  animation-duration: 0.7s;

  transform: ${(props) =>
    props.hover
      ? `translate(${props.hleft}px, ${props.htop}px)`
      : `translate(0px,0px)`};
`;

const SynesthesiaMechContainer = styled.div`
  display: block;
  flex-shrink: 0;
  height: 600px;
  width: 600px;
  opacity: 0.99;
  z-index: 200;

  margin: 0px;
  margin-bottom: 50px;

  @media only screen and (max-width: 1300px) {
    width: 500px;
    height: 500px;
  }

  @media only screen and (max-width: 700px) {
    width: 400px;
    height: 400px;
  }
  @media only screen and (max-width: 400px) {
    width: 300px;
    height: 300px;
  }
`;
