import { useEffect } from 'react';
import create, { UseStore } from 'zustand';

export type authorStore = {
  author: string | null;
};

export const useAuthor = create<authorStore>(() => ({
  author: null,
}));

export const useAndSetAuthor = (newAuthor: string) => {
  useEffect(() => {
    useAuthor.setState({ author: newAuthor });
  }, []);
  return useAuthor((state) => state.author);
};
